import { type Environment, LeagueConfig } from '@leagueplatform/core';
import { ovatientTheme } from 'themes/ovatient-metrohealth.theme';
import FeatureHighlightBackground from 'assets/feature-highlights-background.png';
import JourneyHeaderBackground from 'assets/journey-header-background.svg';
import EN_STRING_OVERRIDES from 'locales/en.json';
import ES_STRING_OVERRIDES from 'locales/es.json';
import { MASONRY_WS_CONTAINERS } from 'common/constants';
import OvatientExternalLinkIcon from 'assets/ovatient-external-link-icon.svg';

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
  VITE_TENANT_ID,
  VITE_CLIENT_ID,
  VITE_AUTH0_CLIENT_CONNECTION,
  VITE_AUTH0_AUDIENCE,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN,
  client_id: VITE_AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  connection: VITE_AUTH0_CLIENT_CONNECTION,
  aud: VITE_AUTH0_AUDIENCE,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

export const config: LeagueConfig = {
  core: {
    clientId: VITE_CLIENT_ID,
    tenantId: VITE_TENANT_ID,
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    contentUrl: VITE_CONTENT_URL as string,
    appEnvironment: VITE_APP_ENV as Environment,
    customMethods: {
      handleLink: async (url: any) => {
        await window.open(url, '_blank', 'noreferrer');
      },
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    i18n: {
      strings: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
        es: ES_STRING_OVERRIDES,
      },
    },
    ui: {
      theme: ovatientTheme,
      themeOptions: {
        customIcons: {
          interfaceExternalLink: OvatientExternalLinkIcon,
        },
      },
      features: { pointsEnabled: true },
      components: {
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.screenHeader.background',
        },
      },
    },
  },
  assets: {
    overrides: {
      FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightBackground,
      EMPTY_STATE_CONFIGURABLE_ACTIVITIES: ' ',
    },
  },
  aboutMe: {
    showAccountBanner: false,
    showLandingPageBackButton: true,
    showOptionsMenu: true,
  },
  careDiscovery: {
    masonryAppId: {
      left: MASONRY_WS_CONTAINERS.GET_CARE.LEFT,
    },
    masonryAppVersion: '1.5',
  },
  healthProfile: {
    ratingsBarEnabled: true,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: true,
  },
  careCollections: {
    showCareCollectionFeedbackComponent: true,
  },
  claims: {
    supportLink: '/support',
    filterableProperties: {
      // serviceDate: ['last_12_months', 'year_to_date', 'prior_year'],
      status: ['pending', 'approved', 'adjusted'],
    },
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: true,
      shouldShowFilterChips: true,
    },
  },
  healthJourney: {
    tabs: ['activities', 'progress', 'history', 'explore'],
    headerConfig: {
      backgroundImageSrc: JourneyHeaderBackground,
    },
  },
  __internal: {
    shouldApplyCSSReset: true,
    shouldApplyLegacyGlobalStyles: true,
  },
};
