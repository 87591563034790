// Masonry v1 or v1.5 containers
export const MASONRY_WS_CONTAINERS = {
  HOME_PAGE: 'ovatient-metrohealth_home',
  GET_CARE: {
    LEFT: 'get-care',
  },
};

// MASONRY 2.0 containers
export const MASONRY_ROOT_NODES = {
  NOTIFICATIONS: 'notifications$root',
  NOTIFICATIONS_DETAILS: 'notificationDetails$root',
};

export const MASONRY_NAMESPACES = {
  OVATIENT: 'ovatient',
  NOTIFICATIONS: 'ovatient_notifications',
  NOTIFICATION_DETAILS: 'ovatient_notification_details',
};

export const MASONRY_WIDGET_TYPES = {
  NOTIFICATION_CARD: 'ovatientNotificationCard',
  NOTIFICATION_DETAILS_CARD: 'ovatientNotificationDetailsCard',
  MYCHART_NOTIFICATIONS: 'mychart_notifications_custom_component',
};

export const MASONRY_ACTION_TYPES = {
  DEEPLINK: 'deeplink',
};
