import {
  MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import {
  type MasonryDeeplinkAction,
  type MasonryDriverAction,
} from '@leagueplatform/masonry-renderers';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import type { NotificationTypes } from 'common/types';
import { NotificationCard } from '../notification-card.component';

type NotificationCardNodeProperties = {
  heading: string;
  body: string;
  notificationType: NotificationTypes;
  badgeText: string;
  actionLink: string;
  isExternalLink: boolean;
  nodeId: string;
};

type NotificationCardNodeActions = {
  onClick?: MasonryDriverAction;
};

type NotificationCardNode = MasonryEngineNode<
  'ovatientNotificationCard',
  NotificationCardNodeProperties,
  NotificationCardNodeActions
>;

type NotificationCardProps =
  MasonryEngineNodeRendererProps<NotificationCardNode>;

export const NotificationCardRenderer = ({
  heading,
  body,
  notificationType,
  badgeText,
  isExternalLink,
  onClick,
  nodeId,
}: NotificationCardProps) => {
  const invokeAction = useMasonryEngineActionEmitter();
  return (
    <NotificationCard
      heading={heading}
      body={body}
      notificationType={notificationType}
      badgeText={badgeText}
      isExternalLink={isExternalLink}
      nodeId={nodeId}
      actionLink={(onClick as MasonryDeeplinkAction)?.payload.url}
      onCardClick={(event) => {
        if (onClick) {
          invokeAction({
            event,
            ...onClick,
          });
        }
      }}
    />
  );
};
