import {
  Box,
  Icon,
  ParagraphText,
  StackItem,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useFooterConfig } from 'hooks/use-footer-config.hook';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';
import { useConfigProperty } from '@leagueplatform/core';

const AppStoreIconImage = styled('img', {
  width: '158px',
});

export const GlobalFooter = () => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  const { footerLinks, appLinks } = useFooterConfig();

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  return (
    <Box css={{ marginBlockStart: '$four' }}>
      <StackLayout
        horizontalAlignment="center"
        css={{
          backgroundColor: '$decorativeBrandPrimaryDarkest',
          paddingInline: '$three',
          paddingBlock: isBelowTablet ? '$oneAndHalf' : '$three',
          '&& *': {
            color: '$onSurfaceTextReversed',
            '&:focus': {
              borderRadius: '$small',
              outlineColor: '$colors$decorativeBrandPrimaryPastel',
            },
          },
        }}
      >
        <StackLayout
          orientation={isBelowTablet ? 'vertical' : 'horizontal'}
          horizontalAlignment="center"
          css={{
            maxWidth: 1196,
          }}
        >
          <StackItem
            css={{
              gap: '$threeQuarters',
              marginInlineEnd: isBelowTablet ? '$none' : '$twoAndHalf',
              marginBlockEnd: isBelowTablet ? '$two' : '$none',
            }}
          >
            {appLinks.map((appLink) => (
              <a key={appLink.href} href={appLink.href}>
                <AppStoreIconImage alt={appLink.alt} src={appLink.src} />
              </a>
            ))}
          </StackItem>

          <StackLayout>
            <StackLayout
              orientation={isBelowTablet ? 'vertical' : 'horizontal'}
              spacing={isBelowTablet ? '$half' : '$two'}
              css={{
                flexGrow: 0,
                marginBlockEnd: '$oneAndHalf',
                alignItems: isBelowTablet ? '' : 'center',
              }}
            >
              {footerLinks.map((footerLink) => (
                <Box
                  as="a"
                  onClick={(e) => {
                    e?.preventDefault();
                    handleLinkCallback(footerLink.to, footerLink.target);
                  }}
                  href={footerLink.to}
                  target={footerLink.target}
                  rel="noopener noreferrer"
                  key={footerLink.text}
                  css={{
                    display: 'flex',
                    typography: '$bodyTwo',
                  }}
                >
                  {footerLink.text}
                  {footerLink.linkIcon && (
                    <Icon
                      icon={footerLink.linkIcon}
                      size="$one"
                      css={{
                        marginInlineStart: '$half',
                        alignSelf: 'center',
                      }}
                    />
                  )}
                </Box>
              ))}
            </StackLayout>

            <Box>
              <ParagraphText
                css={{
                  typography: '$caption',
                }}
              >
                {formatMessage({
                  id: 'STR_FOOTER_DISCLAIMER',
                })}
              </ParagraphText>
            </Box>
          </StackLayout>
        </StackLayout>
      </StackLayout>
    </Box>
  );
};
