import {
  styled,
  TextAction,
  type GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import LOGO from 'assets/metrohealth-logo.svg';

type LogoProps = {
  width: GDSStyleObject['width'];
  css?: GDSStyleObject;
  src?: string;
  alt?: string;
  href?: string;
};

const StyledImage = styled('img');

export const Logo = ({ width, css, src = LOGO, alt, href }: LogoProps) => {
  const { formatMessage } = useIntl();
  const defaultAlt = formatMessage({ id: 'STR_ALT_METROHEALTH_LOGO' });
  return (
    <TextAction as="a" href={href} css={css}>
      <StyledImage
        role="presentation"
        alt={alt || defaultAlt}
        src={src}
        css={{
          width,
          cursor: href ? 'pointer' : 'default',
        }}
      />
    </TextAction>
  );
};
