import {
  StatusBadge,
  type GDSStatusBadgeProps,
  Box,
  styled,
} from '@leagueplatform/genesis-core';
import type { NotificationTypes } from 'common/types';

type NotificationTypeBadgeProps = {
  label: string;
  type: NotificationTypes;
  showLight?: GDSStatusBadgeProps['showLight'];
};

const NotificationTypeBadgeBase = styled(StatusBadge, {
  variants: {
    status: {
      appointment: {
        backgroundColor: '$criticalBackgroundSubdued',
      },
      prescription: {
        backgroundColor: '$successBackgroundSubdued',
      },
      test: {
        backgroundColor: '$decorativeBrandPrimaryBrightest',
      },
      testResult: {
        backgroundColor: '$decorativeBrandPrimaryBrightest',
      },
    },
  },
});

export const NotificationTypeBadge = ({
  label,
  type,
  showLight,
}: NotificationTypeBadgeProps) => (
  <Box>
    <NotificationTypeBadgeBase
      label={label}
      status={type}
      showLight={Boolean(showLight)}
    />
  </Box>
);
