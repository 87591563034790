import { useState } from 'react';
import {
  AppBarItem,
  AppBarNavLink,
  AppBarHeader,
  AppBarNav,
  AppBarNavList,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  queryHelpers,
  useMediaQuery,
  Button,
  Modal,
  Box,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { NAV_BAR_HEIGHT, RESPONSIVE_NAV_BAR_HEIGHT } from 'common/ui';
import { sendCapabilityNavLinkEvent } from 'utils/analytics-events';
import { useModal } from 'hooks/use-modal.hook';
import {
  NavLink,
  useNavLinksList,
} from 'components/header-nav/navbar/use-nav-links-list.hook';
import { UserDropdown } from 'components/header-nav/navbar/user-dropdown/user-dropdown.component';
import { MobileNav } from 'components/header-nav/navbar/mobile-nav/mobile-nav.component';
import { DeleteAccountModal } from 'components/modals/delete-account-modal.component';
import { AccountDeletedModal } from 'components/modals/account-deleted-modal.component';
import { Logo } from 'components/logo.component';
import MyCareAnywhereLogo from 'assets/mycare-anywhere-logo.svg';
import { ROUTE_PATHS } from 'common/constants';

export const AppNavBarComponent = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { navLinks } = useNavLinksList();
  const { formatMessage } = useIntl();
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));
  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();
  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();

  const handleAccountDeleted = () => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Box
      css={{
        '> header > nav > ul > li > a[aria-current="page"]::after': {
          backgroundColor: '$interactiveActionPrimary',
        },
        '.user-dropdown-item_link .GDS-icon': {
          color: '$onSurfaceIconPrimary',
        },
      }}
    >
      <AppBarHeader
        navHeight={isAboveLaptop ? NAV_BAR_HEIGHT : RESPONSIVE_NAV_BAR_HEIGHT}
        headerCSS={{
          boxShadow: isAboveLaptop
            ? '0px 4px 16px 0px rgb(0 0 0 /8%)'
            : '0px 4px 8px 0px rgb(0 0 0 /15%)',
          '@laptop': {
            paddingInlineStart: '$three',
            paddingInlineEnd: '$three',
          },
          '@desktop': {
            paddingInlineStart: '$three',
            paddingInlineEnd: '$three',
          },
        }}
      >
        {isAboveLaptop ? (
          // Desktop Navigation
          <AppBarNav>
            <Logo
              width={159}
              css={{ marginInlineEnd: '$three' }}
              href={ROUTE_PATHS.HOME}
            />
            <AppBarNavList>
              {/* App Nav Home & Capability Areas */}
              {navLinks.map((navLink: NavLink) => (
                <AppBarNavLink
                  to={navLink.to}
                  key={navLink.message_id}
                  onClick={sendCapabilityNavLinkEvent(navLink.message_id)}
                  color="onSurface.text.primary"
                  fontWeight="medium"
                  hoverStyle={{ color: 'interactive.action.primary' }}
                  decorativeBarColor="interactive.action.primary"
                  listItemProps={{
                    flexGrow: 0,
                    marginInlineEnd: 'twoAndHalf',
                  }}
                >
                  {formatMessage({ id: navLink.message_id })}
                </AppBarNavLink>
              ))}
              <AppBarItem
                justifyContent="flex-end"
                marginInlineEnd={isAboveLaptop ? 'five' : 0}
                css={{
                  '#user_dropdown_menu': {
                    top: '66px',
                  },
                }}
              >
                {/* App Nav User Dropdown */}
                <Logo
                  width={107}
                  href={ROUTE_PATHS.HOME}
                  src={MyCareAnywhereLogo}
                  css={{ marginInlineEnd: '$oneAndHalf' }}
                  alt={formatMessage({ id: 'STR_MYCARE_ANYWHERE_LOGO' })}
                />
                <UserDropdown handleDeleteAccount={toggleDeleteAccountModal} />
              </AppBarItem>
            </AppBarNavList>
          </AppBarNav>
        ) : (
          <Box
            css={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridTemplateRows: '1fr',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {/* Mobile Navigation */}
            <Modal.Root
              open={menuOpen}
              onOpenChange={() => {
                setMenuOpen((previous) => !previous);
              }}
            >
              <Modal.Trigger>
                <Button
                  icon="interfaceMenu"
                  hideLabel
                  quiet
                  priority="tertiary"
                  size="large"
                  aria-controls={undefined}
                >
                  {formatMessage({ id: 'STR_OPEN_MENU' })}
                </Button>
              </Modal.Trigger>
              <Modal.Content
                layout="right"
                css={{
                  '.GDS-modal': { maxWidth: 335 },
                  '.GDS-modal-content': { paddingInlineStart: '$one' },
                }}
              >
                <MobileNav
                  closeModal={() => setMenuOpen(false)}
                  handleDeleteAccount={toggleDeleteAccountModal}
                />
              </Modal.Content>
            </Modal.Root>
            <StackLayout
              orientation="horizontal"
              horizontalAlignment="center"
              verticalAlignment="center"
              css={{
                paddingInlineEnd: '$one',
              }}
            >
              <Logo
                width={114}
                css={{ marginInlineEnd: '$one' }}
                href={ROUTE_PATHS.HOME}
              />
              <Logo
                width={126}
                href={ROUTE_PATHS.HOME}
                src={MyCareAnywhereLogo}
                alt={formatMessage({ id: 'STR_MYCARE_ANYWHERE_LOGO' })}
              />
            </StackLayout>
          </Box>
        )}
      </AppBarHeader>
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        <DeleteAccountModal onSubmit={toggleAccountDeletedModal} />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          toggleAccountDeletedModal();
          handleAccountDeleted();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
    </Box>
  );
};
