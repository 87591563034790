import {
  Box,
  Checkbox,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { Formik, Form, Field } from 'formik';
import clipboardImage from 'assets/clipboard.svg';
import {
  EXTERNAL_URLS,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
} from 'common/constants';
import { useUpdateUserProfile } from 'api/update-user-profile';
import { useAcceptUserTerms } from 'hooks/use-accept-user-terms';
import { OnboardingMachineContext } from 'components/onboarding/onboarding.machine';
import { BasePage } from 'components/BasePage';
import { CTAFooter } from 'components/cta-footer/cta-footer.component';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';

type FormValues = {
  emailOptIn: boolean;
};

export const ConsentView = () => {
  const { send } = OnboardingMachineContext.useActorRef();
  const updateUserProfileMutation = useUpdateUserProfile();
  const acceptUserTermsMutation = useAcceptUserTerms();
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.ONBOARDING,
    sub_product_area: SUB_PRODUCT_AREA.CONSENT,
    screen_name: SCREEN_NAMES.CONSENT,
  });

  const trackCTAButtonClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.CONSENT,
      screen_name: SCREEN_NAMES.CONSENT,
      detail: 'agree and continue',
    });
  };

  return (
    <BasePage isPrivatePage={false}>
      <StackLayout
        spacing="$oneAndHalf"
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          justifySelf: 'center',
          maxWidth: 'calc(327px + $one)',
          paddingInline: '$one',
          paddingBlockStart: '$two',
          paddingBlockEnd: 'calc(100px + $two)',
        }}
      >
        <img src={clipboardImage} alt="" />
        <HeadingText level="1" size="xl">
          {formatMessage({ id: 'STR_COMPLETE_YOUR_ACCOUNT' })}
        </HeadingText>
        <ParagraphText
          css={{
            textAlign: 'center',
          }}
        >
          {formatMessage({
            id: 'STR_PRIVACY_POLICY_AND_TOS_POST_AUTH_1',
          })}{' '}
          <a
            href={EXTERNAL_URLS.PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage({ id: 'STR_PRIVACY_POLICY' })}
          </a>{' '}
          {formatMessage({ id: 'STR_AND' })}{' '}
          <a
            href={EXTERNAL_URLS.TERMS_OF_SERVICE}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage({ id: 'STR_TERMS_OF_SERVICE' })}
          </a>
          .
        </ParagraphText>
        <Formik<FormValues>
          initialValues={{
            emailOptIn: false,
          }}
          onSubmit={async (form) => {
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: PRODUCT_AREA.ONBOARDING,
              screen_name: SCREEN_NAMES.CONSENT,
              detail: 'email opt-in checkbox',
              email_status: form.emailOptIn ? 'checked' : 'unchecked',
            });
            if (form.emailOptIn) {
              await updateUserProfileMutation.mutateAsync(
                {
                  opted_into_marketing_communications: true,
                },
                {
                  onError() {
                    toastActions.add({
                      type: TOAST_STATUS.ERROR,
                      textId: 'STR_ERROR_CAPTION_PROFILE',
                    });
                  },
                },
              );
            }
            await acceptUserTermsMutation.mutateAsync(undefined, {
              onSuccess: () => {
                send({ type: 'userAgreedToContinue' });
              },
              onError() {
                toastActions.add({
                  type: TOAST_STATUS.ERROR,
                  textId: 'ERROR_CAPTION_SAVE',
                });
              },
            });
          }}
        >
          <Form>
            <Box
              css={{ display: 'flex', flexDirection: 'column', gap: '$half' }}
            >
              <Field
                as={Checkbox}
                type="checkbox"
                id="emailOptIn"
                name="emailOptIn"
                label={formatMessage({ id: 'STR_EMAIL_OPT_IN_POST_AUTH' })}
              />
            </Box>
            <CTAFooter
              showPrimaryBtn
              primaryBtnLabel={formatMessage({ id: 'STR_AGREE_AND_CONTINUE' })}
              primaryBtnProps={{
                type: 'submit',
                onClick: trackCTAButtonClick,
                loading:
                  updateUserProfileMutation.isLoading ||
                  acceptUserTermsMutation.isLoading,
              }}
            />
          </Form>
        </Formik>
      </StackLayout>
    </BasePage>
  );
};
