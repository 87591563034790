/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';

import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import {
  AboutMeRoutes,
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  HomePage,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
  PersonalInformationPage,
  CommunicationPreferencesPage,
  AboutPage,
  NotificationsPage,
  NotificationDetailsPage,
} from 'components/LazyRoutes';

import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants';
import ErrorPage from 'pages/Error404';
import { Onboarding } from 'components/onboarding/onboarding.component';
import { BasePage } from 'components/BasePage';
import { AuthSessionContainer } from 'components/auth-session-container/auth-session-container';
import 'masonry-registries'; // initialize custom masonry registries
import { MemberPathRedirect } from './member-path-redirect/member-path-redirect.component';

function AppRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_PATHS.SIGN_IN} component={AuthViewLogin} />
      <Route path={ROUTE_PATHS.SIGN_OUT} component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthSessionContainer>
        <Onboarding>
          <BasePage>
            <Switch>
              <Route path={ROUTE_PATHS.MEMBER_REDIRECT}>
                <MemberPathRedirect />
              </Route>
              <Route path={ROUTE_PATHS.HOME} component={HomePage} exact />
              <Route
                path={ROUTE_PATHS.PERSONAL_INFO}
                component={PersonalInformationPage}
                exact
              />
              <Route
                path={ROUTE_PATHS.COMMUNICATION_PREFERENCE}
                component={CommunicationPreferencesPage}
                exact
              />
              <Route path={ROUTE_PATHS.ABOUT} component={AboutPage} exact />
              <Route
                path={[
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                  getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                  getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                ]}
                component={HealthJourneyRoutes}
                key="health-journey"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                component={AboutMeRoutes}
                key="about-me"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
                key="care-collections"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={CareDiscoveryRoutes}
                key="care-discovery"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
                component={HealthNeedPages}
                key="health-needs"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={WalletRoutes}
                key="wallet"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                component={HealthProfileRoutes}
                key="health-profile"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
                component={OnboardingRoutesWithProvider}
                key="onboarding"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                component={ClaimsRoutes}
                key="claims"
              />
              <Route
                path={ROUTE_PATHS.NOTIFICATIONS}
                component={NotificationsPage}
                exact
              />
              <Route
                path={ROUTE_PATHS.NOTIFICATION_DETAILS}
                component={NotificationDetailsPage}
              />
              <Route component={ErrorPage} />
            </Switch>
          </BasePage>
        </Onboarding>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
