import {
  HeadingText,
  ParagraphText,
  ImageMessage,
  VisuallyHidden,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import WarningIcon from 'assets/warning-icon.svg';
import { IMAGE_MESSAGE_ICON_WIDTH } from 'common/ui';
import { ResponsiveButtonRow } from 'components/responsive-button-row/responsive-button-row.component';
import { ModalActionButton } from 'components/modals/common/modal-action-button.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';
import { useDeleteAccountMutation } from 'hooks/use-delete-account-mutation/use-delete-account-mutation.hook';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';

export interface Props {
  onSubmit: () => void;
}
export const DeleteAccountModal = ({ onSubmit }: Props) => {
  const { formatMessage } = useIntl();

  const { mutate: deleteAccount, isLoading: isDeletingAccount } =
    useDeleteAccountMutation();

  const handleDeleteAccount = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.DELETE_ACCOUNT,
      detail: 'confirm deletion',
    });
    deleteAccount(
      {},
      {
        onSuccess: onSubmit,
      },
    );
  };

  const trackCancelDeletion = (detail: string) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.DELETE_ACCOUNT,
      detail,
    });
  };

  const isBelowTablet = useIsBelowTablet();

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' })}
      onClose={() => trackCancelDeletion('close')}
    >
      <Modal.Title>
        <VisuallyHidden>
          {formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' })}
        </VisuallyHidden>
      </Modal.Title>
      <ImageMessage
        image={WarningIcon}
        imageWidth={IMAGE_MESSAGE_ICON_WIDTH}
        css={{
          paddingBlockStart: '$one',
          paddingBlockEnd: '$two',
          textAlign: isBelowTablet ? 'center' : 'left',
        }}
      >
        <HeadingText level="2" size="lg" css={{ marginBlockEnd: '$one' }}>
          {formatMessage({ id: 'STR_ARE_YOU_SURE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({ id: 'STR_DELETE_ACCOUNT_BODY_1' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'STR_DELETE_ACCOUNT_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
      <ResponsiveButtonRow
        primaryBtn={
          <ModalActionButton
            actionType="submit"
            onClick={handleDeleteAccount}
            labelId="STR_YES_DELETE_ACCOUNT"
            isDestructive
            isLoading={isDeletingAccount}
          />
        }
        secondaryBtn={
          <ModalActionButton
            actionType="cancel"
            onClick={() => trackCancelDeletion('cancel deletion')}
            labelId="STR_NEVER_MIND"
            cssStyle={{ color: '$secondaryTextDefault' }}
          />
        }
      />
    </BaseModalContainer>
  );
};
