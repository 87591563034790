import { StackLayout, TextAction } from '@leagueplatform/genesis-core';
import { CompatLink } from '@leagueplatform/routing';
import {
  MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { isExternalUrl } from '@leagueplatform/web-common';
import { SectionHeader } from '@leagueplatform/genesis-core-alpha';
import type { NotificationTypes } from 'common/types';
import { NotificationCard } from 'components/notifications/notification-card.component';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from 'common/constants';

type NotificationStackItem = {
  heading: string;
  notificationType: NotificationTypes;
  body: string;
  badgeText: string;
  url: string;
  isExternalLink: boolean;
};

type NotificationStackNodeProperties = {
  headerLinkHint: string;
  headerLinkUrl: string;
  heading: string;
  items: Array<NotificationStackItem>;
  nodeId: string;
};

type NotificationStackNode = MasonryEngineNode<
  'mychart_notifications_custom_component',
  NotificationStackNodeProperties
>;

type NotificationStackProps =
  MasonryEngineNodeRendererProps<NotificationStackNode>;

export const NotificationStackRenderer = ({
  headerLinkHint,
  headerLinkUrl,
  heading,
  items,
  nodeId,
}: NotificationStackProps) => {
  const productAreaAnalytics = () => {
    if (nodeId.includes('notifications_get_care')) {
      return PRODUCT_AREA.GET_CARE;
    }
    if (nodeId.includes('notifications_homepage')) {
      return PRODUCT_AREA.HOME_SCREEN;
    }

    return undefined;
  };

  const trackViewMoreButtonClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: productAreaAnalytics(),
      sub_product_area: 'linkout tile',
      screen_name: SCREEN_NAMES.NOTIFICATION_CARDS,
      detail: 'view more',
    });
  };

  if (!items || items?.length === 0) return null;
  return (
    <StackLayout horizontalAlignment="stretch" spacing="$one">
      <SectionHeader
        title={heading}
        headingLevel="2"
        textAction={
          <TextAction
            as={CompatLink}
            to={headerLinkUrl}
            css={{ textDecoration: 'none' }}
            onClick={trackViewMoreButtonClick}
          >
            {headerLinkHint}
          </TextAction>
        }
        css={{
          '& .GDS-heading-text': { typography: '$headingTwo' },
        }}
      />
      <StackLayout spacing="$half">
        {items.map((item: NotificationStackItem) => (
          <NotificationCard
            key={`${item.heading}-${item.body}-${item.notificationType}`.replace(
              ' ',
              '-',
            )}
            heading={item.heading}
            body={item.body}
            notificationType={item.notificationType}
            badgeText={item.badgeText}
            isExternalLink={isExternalUrl(item.url)}
            actionLink={item.url}
            nodeId={nodeId}
          />
        ))}
      </StackLayout>
    </StackLayout>
  );
};
