/* eslint-disable react/prop-types */
import { useIntl } from '@leagueplatform/locales';
import { ICONS, Icon } from '@leagueplatform/ui-kit';
import {
  Button,
  GDSStyleObject,
  Modal,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
// eslint-disable-next-line @leagueplatform/prevent-import-from-dist
import {
  GDSButtonPriority,
  GDSButtonSize,
} from '@leagueplatform/genesis-core/dist/components/button/button';
import type { LocalesKey } from 'common/types';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';

interface ModalActionButtonProps {
  actionType: 'close' | 'submit' | 'cancel';
  onClick?: () => void;
  labelId: LocalesKey;
  cssStyle?: GDSStyleObject;
  isDestructive?: boolean;
  isLoading?: boolean;
  buttonWidth?: 'fillContainer' | 'hugContents';
}

export const ModalActionButton: React.FC<ModalActionButtonProps> = ({
  actionType,
  onClick,
  labelId,
  cssStyle,
  isDestructive = false,
  isLoading,
  buttonWidth,
}) => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  // Determine button settings based on actionType
  const settings = {
    close: {
      priority: 'tertiary',
      icon: ICONS.CLOSE,
      size: 'small',
    },
    submit: {
      priority: 'primary',
      size: isBelowTablet ? 'large' : 'medium',
    },
    cancel: {
      priority: 'tertiary',
      size: isBelowTablet ? 'large' : 'medium',
    },
  }[actionType];

  return (
    <Modal.Close>
      <Button
        type={actionType === 'submit' ? 'submit' : 'button'}
        onClick={onClick}
        priority={settings.priority as GDSButtonPriority}
        size={settings.size as GDSButtonSize}
        icon={settings.icon ? 'tinyClose' : undefined}
        hideLabel={actionType === 'close'}
        css={cssStyle}
        destructive={isDestructive}
        width={buttonWidth || isBelowTablet ? 'fillContainer' : 'hugContents'}
        loading={isLoading}
      >
        {actionType === 'close' ? (
          <VisuallyHidden>{formatMessage({ id: labelId })}</VisuallyHidden>
        ) : (
          formatMessage({ id: labelId })
        )}
        {actionType === 'close' && (
          <Icon icon={settings.icon as any} size={16} aria-hidden="true" />
        )}
      </Button>
    </Modal.Close>
  );
};
