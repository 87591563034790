import { Box } from '@leagueplatform/genesis-core';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { NavLink } from '@leagueplatform/routing';

import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';
import { useConfigProperty } from '@leagueplatform/core';

export const defaultMobileNavLinkStyles = {
  '&&': {
    display: 'flex',
    alignItems: 'center',
    color: '$onSurfaceTextPrimary',
    typography: '$buttonTwo',
    borderRadius: '$medium',
    padding: '$half',
    '&[aria-current="page"], &.active': {
      color: '$interactiveActionPrimary',
      borderBottomColor: '$interactiveActionPrimary',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '$interactiveActionHovered',
    },
  },
};

type MobileNavLinkProps = {
  children: React.ReactNode;
  linkData: any;
  onLinkClick: () => void;
};

export const MobileNavLink = ({
  children,
  linkData,
  onLinkClick,
}: MobileNavLinkProps) => {
  const { text, color } = linkData;
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  if ('to' in linkData) {
    const { to } = linkData;

    if ('target' in linkData && isAbsoluteUrl(linkData?.to)) {
      // Nav item is an external link
      const { target } = linkData;

      return (
        <Box
          as="a"
          href={to}
          target={target}
          rel="noopener noreferrer"
          css={{
            ...defaultMobileNavLinkStyles,
            ...(color && { color: `${color} !important` }),
          }}
          onClick={(e) => {
            e?.preventDefault();
            handleLinkCallback(to, target);
            onLinkClick();
          }}
        >
          {children}
          {target === '_blank' && <OpensInANewTabMessage />}
        </Box>
      );
    }
    // Nav item is an internal link
    return (
      <Box
        as={NavLink}
        to={to}
        id={`mobile-nav-link-${text}`}
        css={{
          ...defaultMobileNavLinkStyles,
          ...(color && { color: `${color} !important` }),
        }}
        onClick={onLinkClick}
      >
        {children}
      </Box>
    );
  }

  // Nav item is a button link
  if ('onClick' in linkData)
    return (
      <Box
        as="li"
        css={{
          ...defaultMobileNavLinkStyles,
          ...(color && { color: `${color} !important` }),
        }}
        onClick={onLinkClick}
      >
        {children}
      </Box>
      // TODO: Need to check - Button is using additional span inside it, which is disturbing css alignment with other links
      // <Box as="li" css={{ width: '100%' }}>
      //   <Button
      //     quiet
      //     size="medium"
      //     priority="tertiary"
      //     css={{
      //       ...defaultMobileNavLinkStyles,
      //       ...(color && { color: `${color} !important`}),
      //     }}
      //     onClick={onLinkClick}
      //   >
      //     {children}
      //   </Button>
      // </Box>
    );

  return null;
};
