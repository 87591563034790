import { useMutation } from 'react-query';
import { useErrorBoundary } from 'react-error-boundary';
import { socketAsFetch } from 'utils/socket-as-fetch';
import { MUTATION_KEYS, MESSAGE_TYPES } from 'common/constants';

export const useTestResultsViewedMutation = () => {
  const { showBoundary } = useErrorBoundary();

  return useMutation<unknown, unknown, {}, unknown>({
    mutationKey: [MUTATION_KEYS.UPDATE_TEST_RESULTS],
    mutationFn: async () => {
      try {
        return await socketAsFetch({
          message_type: MESSAGE_TYPES.SET_USER_PROFILE,
          info: {
            custom_fields: [
              {
                name: 'test_results_viewed_time',
                value: new Date().toISOString(),
              },
            ],
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          showBoundary(e);
        }
        return false;
      }
    },
  });
};
